<template>
  <div class="box">
    <div class="box__header">
      <h3>Решение</h3>

      <div class="buttons">
        <VButton
          v-if="$store.getters['permission/permissionUpdateReclamationResolution']"
          text="Редактировать"
          :class="isEdit && 'disabled filter-gray'"
          size="sm"
          icon="edit"
          @click.native="turnOnIsEdit"
        />
      </div>
    </div>

    <ReclamationExternalResolutionCardDetails
      v-if="!isEdit"
      :reclamation="reclamation"
    />

    <ReclamationExternalResolutionEditForm
      v-else
      :reclamation="reclamation"
      @update-success="onUpdateSuccess"
      @cancel="turnOffIsEdit"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import { ReclamationExternalResolutionCardDetails } from '@/entities/reclamation-external'
import { ReclamationExternalResolutionEditForm } from '@/features/reclamation-external/edit-resolution'
import { mixinIsEdit } from '@/mixins/mixinIsEdit'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    ReclamationExternalResolutionCardDetails,
    ReclamationExternalResolutionEditForm,
    VButton
  },
  mixins: [mixinIsEdit],

  computed: {
    ...mapState('reclamationExternal/details', {
      reclamation: 'reclamation'
    })
  },

  methods: {
    ...mapMutations('reclamationExternal/launchIntoProduction', {
      openModalLaunchIntoProduction: 'OPEN_MODAL'
    }),

    onUpdateSuccess () {
      this.turnOffIsEdit()

      if (!this.reclamation.isIndicatorForLaunchIntoProduction()) return

      if (!this.reclamation.hasReclamationOrder()) {
        return this.openModalLaunchIntoProduction('form')
      }

      if (this.reclamation.isStatusLaunchIntoProduction()) {
        this.openModalLaunchIntoProduction('go-to-order')
      }
    }
  }
}
</script>
