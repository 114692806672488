<template>
  <div class="box">
    <div class="box__header">
      <h3>Общая информация</h3>

      <div class="buttons">
        <ReclamationExternalSpringReceived
          v-if="$store.getters['permission/permissionUpdateReclamationSpringReceived'] && reclamation.canSpringReceived()"
          :reclamation="reclamation"
        />

        <VButton
          v-if="visibleEditBtn"
          text="Редактировать"
          :class="isEdit && 'disabled filter-gray'"
          size="sm"
          icon="edit"
          @click.native="turnOnIsEdit"
        />
      </div>
    </div>

    <ReclamationExternalCardDetails
      v-if="!isEdit"
      :reclamation="reclamation"
    />

    <ReclamationExternalEditForm
      v-else
      :reclamation="reclamation"
      @update-success="onUpdateSuccess"
      @update-modal="onUpdateModal"
      @cancel="turnOffIsEdit"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import { ReclamationExternalCardDetails, reclamationExternalModel } from '@/entities/reclamation-external'
import { ReclamationExternalEditForm } from '@/features/reclamation-external/edit'
import { ReclamationExternalSpringReceived } from '@/features/reclamation-external/spring-received'
import { mixinIsEdit } from '@/mixins/mixinIsEdit'
import { mapMutations, mapState } from 'vuex'
import { goToPage } from '@/utils/router'

export default {
  components: {
    ReclamationExternalCardDetails,
    ReclamationExternalEditForm,
    VButton,
    ReclamationExternalSpringReceived
  },
  mixins: [mixinIsEdit],
  computed: {
    ...mapState('reclamationExternal/details', {
      reclamation: 'reclamation'
    }),
    visibleEditBtn () {
      return this.$store.getters['permission/permissionUpdateReclamationGeneralInformation'] || this.$store.getters['permission/permissionUpdateReclamationIndicator']
    }
  },
  methods: {
    ...mapMutations('reclamationExternal/launchIntoProduction', {
      openModalLaunchIntoProduction: 'OPEN_MODAL'
    }),

    goToReclamationList () {
      goToPage(this.$store.state.pages.reclamationPages.list, {
        status: reclamationExternalModel.STATUSES[0].value
      })
    },

    onUpdateSuccess () {
      this.turnOffIsEdit()

      if (!this.reclamation.isIndicatorForLaunchIntoProduction()) return

      if (!this.reclamation.hasReclamationOrder()) {
        return this.openModalLaunchIntoProduction('form')
      }
    },

    onUpdateModal () {
      return this.openModalLaunchIntoProduction('form')
    }
  }
}
</script>
